import './App.css';
import React, {useCallback, useEffect, useState} from "react";
import {
    Alert, AppBar, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, Toolbar, Typography
} from "@mui/material";
import UserCredentialsDialog from "./UserCredentialsDialog/UserCredentialsDialog";
import {clearUserToken, getUserToken, saveUserToken} from "./localStorage";
import {DataGrid} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";

var SERVER_URL = "http://localhost:5000";
const States = {
    PENDING: "PENDING",
    USER_CREATION: "USER_CREATION",
    USER_LOG_IN: "USER_LOG_IN",
    USER_AUTHENTICATED: "USER_AUTHENTICATED",
};

function App() {
    let [buyUsdRate, setBuyUsdRate] = useState(null);
    let [sellUsdRate, setSellUsdRate] = useState(null);
    let [lbpInput, setLbpInput] = useState("");
    let [usdInput, setUsdInput] = useState("");
    let [xInput, setXInput] = useState("");
    let [yResult, setYResult] = useState("");
    let [transactionType, setTransactionType] = useState("usd-to-lbp");
    let [calculatorType, setCalculatorType] = useState("usd-to-lbp");
    let [userToken, setUserToken] = useState(getUserToken());
    let [authState, setAuthState] = useState(States.PENDING);
    let [userTransactions, setUserTransactions] = useState([]);

    const columns = [{field: "added_date", headerName: "Added Date", width: 250}, {
        field: "id",
        headerName: "ID #",
        width: 150
    }, {field: "lbp_amount", headerName: "LBP Amount", width: 200}, {
        field: "usd_amount",
        headerName: "USD Amount",
        width: 200
    }, {field: "usd_to_lbp", headerName: "USD to LBP?", width: 100}];

    function logout() {
        setUserToken(null);
        clearUserToken();
    }

    const fetchUserTransactions = useCallback(() => {
        fetch(`${SERVER_URL}/transaction`, {
            headers: {
                Authorization: `bearer ${userToken}`,
            },
        })
            .then((response) => response.json()).then((transactions) => setUserTransactions(transactions));
    }, [userToken]);
    useEffect(() => {
        if (userToken) {
            fetchUserTransactions();
        }
    }, [fetchUserTransactions, userToken]);


    function login(username, password) {
        return fetch(`${SERVER_URL}/authentication`, {
            method: "POST", headers: {
                "Content-Type": "application/json",
            }, body: JSON.stringify({
                user_name: username, password: password,
            }),
        })
            .then((response) => response.json())
            .then((body) => {
                setAuthState(States.USER_AUTHENTICATED);
                setUserToken(body.token);
                saveUserToken(body.token);
            });
    }

    function createUser(username, password) {
        return fetch(`${SERVER_URL}/user`, {
            method: "POST", headers: {
                "Content-Type": "application/json",
            }, body: JSON.stringify({
                user_name: username, password: password,
            }),
        }).then((response) => login(username, password));
    }


    function fetchRates() {
        fetch(`${SERVER_URL}/exchangeRate`)
            .then(response => response.json())
            .then(data => {
                if (data.lbp_to_usd != null) {
                    setBuyUsdRate(data.lbp_to_usd + " LBP/USD");
                } else {
                    setBuyUsdRate("No transactions yet");
                }
                if (data.usd_to_lbp != null) {
                    setSellUsdRate(data.usd_to_lbp + " LBP/USD");
                } else {
                    setSellUsdRate("No transactions yet");
                }
            })
    }

    useEffect(fetchRates, []);

    function addItem() {
        // Buying USD
        let selling_usd = false;

        // Selling USD
        if (transactionType === "usd-to-lbp") {
            selling_usd = true;
        }

        let dictio = {"usd_amount": usdInput, "lbp_amount": lbpInput, "usd_to_lbp": selling_usd};
        let postData = {
            method: 'POST', body: JSON.stringify(dictio), headers: new Headers(userToken === null ? {
                'Content-Type': 'application/json; charset=UTF-8'
            } : {
                'Content-Type': 'application/json; charset=UTF-8', 'Authorization': 'bearer ' + userToken,
            })
        };

        // Clearing fields
        setUsdInput("");
        setLbpInput("");

        fetch(`${SERVER_URL}/transaction`, postData).then(response => fetchRates(response));
    }

    function calculate() {
        if (calculatorType === "usd-to-lbp") {
            setYResult("You can sell " + xInput + " USD for " + (parseFloat(sellUsdRate.slice(0, -8)) * parseFloat(xInput)).toString() + " LBP");
        } else {
            setYResult("You can buy " + (parseFloat(xInput) / parseFloat(buyUsdRate.slice(0, -8))).toString() + " USD with " + xInput + " LBP");
        }
    }

    return (<div>
        <Snackbar
            elevation={6}
            variant="filled"
            open={authState === States.USER_AUTHENTICATED}
            autoHideDuration={2000}
            onClose={() => setAuthState(States.PENDING)}
        >
            <Alert severity="success">Success</Alert>
        </Snackbar>
        <UserCredentialsDialog
            open={authState === States.USER_CREATION}
            onClose={() => setAuthState(States.PENDING)}
            onSubmit={createUser}
            title="Register"
            submitText="Submit"/>
        <UserCredentialsDialog
            open={authState === States.USER_LOG_IN}
            onClose={() => setAuthState(States.PENDING)}
            onSubmit={login}
            title="Login"
            submitText="Submit"/>
        <AppBar position="static">
            <Toolbar classes={{root: "nav"}}>
                <Typography variant="h5">LBP Exchange Tracker</Typography>
                {userToken !== null ? (<Button color="inherit" onClick={logout}>
                    Logout
                </Button>) : (<div>
                    <Button
                        color="inherit"
                        onClick={() => setAuthState(States.USER_CREATION)}
                    >
                        Register
                    </Button>
                    <Button
                        color="inherit"
                        onClick={() => setAuthState(States.USER_LOG_IN)}
                    >
                        Login
                    </Button>
                </div>)}

            </Toolbar>
        </AppBar>
        <div className="wrapper">
            <h2>Today's Exchange Rate</h2>
            <p>LBP to USD Exchange Rate</p>
            <h3>Buy USD: <span id="buy-usd-rate">{buyUsdRate}</span></h3>
            <h3>Sell USD: <span id="sell-usd-rate">{sellUsdRate}</span></h3>
            <hr/>
            <h2>Calculator</h2>
            <form name="transaction-entry">
                <TextField
                    fullWidth
                    multiline={true}
                    rows={1}
                    id="x-amount"
                    label="Amount in LBP or USD to be sold"
                    type="number"
                    value={xInput}
                    onChange={e => setXInput(e.target.value)}
                /><br></br><br></br>
                <FormControl fullWidth>
                    <InputLabel id="calculator-type-label">Transaction Type</InputLabel>
                    <Select
                        fullWidth
                        id="calculator-type"
                        labelId="calculator-type-label"
                        label="Transaction Type"
                        onChange={e => setCalculatorType(e.target.value)}
                    >
                        <MenuItem value="usd-to-lbp">USD to LBP</MenuItem>
                        <MenuItem value="lbp-to-usd">LBP to USD</MenuItem>
                    </Select>
                </FormControl><br></br><br></br>
                <Button
                    id="add-button2"
                    color="primary"
                    variant="contained"
                    onClick={calculate}
                >
                    Calculate
                </Button>
            </form>
            <h3>Result: <span id="buy-usd-rate">{yResult}</span></h3>
        </div>
        <div className="wrapper">
            <h2>Record a recent transaction</h2>
            <form name="transaction-entry">
                <div className="amount-input">
                    <TextField
                        fullWidth
                        multiline={true}
                        rows={1}
                        id="lbp-amount"
                        label="LBP Amount"
                        type="number"
                        value={lbpInput}
                        onChange={e => setLbpInput(e.target.value)}
                    /><br></br><br></br>
                    <TextField
                        fullWidth
                        multiline={true}
                        rows={1}
                        id="usd-amount"
                        label="USD Amount"
                        type="number"
                        value={usdInput}
                        onChange={e => setUsdInput(e.target.value)}
                    /><br></br><br></br>
                </div>
                <FormControl fullWidth>
                    <InputLabel id="transaction-type-label">Transaction Type</InputLabel>
                    <Select
                        fullWidth
                        id="transaction-type"
                        labelId="transaction-type-label"
                        label="Transaction Type"
                        onChange={e => setTransactionType(e.target.value)}
                    >
                        <MenuItem value="usd-to-lbp">USD to LBP</MenuItem>
                        <MenuItem value="lbp-to-usd">LBP to USD</MenuItem>
                    </Select>
                </FormControl><br></br><br></br>
                <Button
                    id="add-button"
                    color="primary"
                    variant="contained"
                    onClick={addItem}
                >
                    Add
                </Button>
            </form>
        </div>
        {userToken && (<div className="wrapper">
            <Typography variant="h5">Your Transactions</Typography>
            <DataGrid
                rows={userTransactions}
                columns={columns}
                autoHeight
            />
        </div>)}
    </div>);
}

export default App;
